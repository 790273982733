import React from 'react';
import "./ImageGallery.css"
import img35 from "../images/img-35.jpg"
import img32 from "../images/img-32.jpg"
import img34 from "../images/img-34.jpg"
const ImageGallery = () => {
  return (
    <div className="gallery-container">
        <div class="title">
            <h1><span>O</span>ur&nbsp;<span>G</span>allery</h1>
        </div>
      <div className="gallery-row">
        <div className="gallery-column">
          <img
            src={img35}
            alt="image1"
          />
        </div>
        <div className="gallery-column">
          <img
            src={img32}
            alt="image2"
          />
        </div>
        <div className="gallery-column">
          <img
            src={img34}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageGallery;
