import React from "react";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Services from "./pages/Services"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Footer from "./components/Footer";
import { MantineProvider } from '@mantine/core';



function App() {
  return (
    <>
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <BrowserRouter>
        <Navbar />
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services/>} />
        <Route path="/aboutus" element={<AboutUs/>} />
        {/* <Route path="/contactus" element={<ContactUs/>} /> */}
        </Routes>
        <Footer/>
      </BrowserRouter>
    </MantineProvider>
    </>
  );
}

export default App;
