import "./Footer.css";
import { FaLocationArrow, FaMobileAlt, FaEnvelope } from "react-icons/fa";
import logo from "../images/1_Transparent_Image.png";
import { Link } from "react-router-dom";

const Footer = () => {
    return <div className="footer">
        <div className="footer-content">
            <div className="col">
                <div className="footer-title">
                  
                  </div>
                <div className="text">Bringing your wedding dreams to life, one detail at a time.
                    </div>
            </div>
            <div className="col">
                <div className="footer-title">Contact</div>
                <div className="c-item">
                    <FaLocationArrow />
                    <div className="text">
                    Bishwanath Chariali, Biswanath, Assam, PINCODE-784176
                        </div>
                </div>
                <div className="c-item">
                        <FaMobileAlt />
                        <div className="text">Phone: +91 7002525541</div>
                    </div>
                    <div className="c-item">
                        <FaEnvelope />
                        <div className="text">Email: contact@assamweddings.com</div>
                    </div>
            </div>
            <div className="col">
            {/* demo text */}
                    
            </div>
        </div>
        <div className="bottom-bar">
        <div className="bottom-bar-content">
                    <span className="text">
                        DESIGNED BY ASSSAMWEDDINGS
                    </span>
                </div>
        </div>
    </div>;
};

export default Footer;
