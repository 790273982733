import { useState, useRef, useEffect } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import './Carousel.css';
import img1 from "../images/img-1.jpeg"
import img2 from "../images/img-2.jpeg"
import img3 from "../images/img-3.jpeg"

const Carousel = () => {
  const carouselRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isDragStart, setIsDragStart] = useState(false);
  const [prevPageX, setPrevPageX] = useState(null);
  const [prevScrollLeft, setPrevScrollLeft] = useState(null);
  const [positionDiff, setPositionDiff] = useState(null);
  const [firstImgWidth, setFirstImgWidth] = useState(null);
  const [scrollWidth, setScrollWidth] = useState(null);

  const showHideIcons = () => {
    if (!carouselRef.current) return;
    const leftIcon = document.getElementById('left');
    const rightIcon = document.getElementById('right');
    setScrollWidth(carouselRef.current.scrollWidth - carouselRef.current.clientWidth);
    leftIcon.style.display = carouselRef.current.scrollLeft === 0 ? 'none' : 'block';
    rightIcon.style.display = carouselRef.current.scrollLeft === scrollWidth ? 'none' : 'block';
  };

  useEffect(() => {
    const firstImg = carouselRef.current.querySelectorAll('img')[0];
    setFirstImgWidth(firstImg.clientWidth + 14);
    showHideIcons();
  }, []);

  const autoSlide = () => {
    if (!carouselRef.current) return;
    if (carouselRef.current.scrollLeft - scrollWidth > -1 || carouselRef.current.scrollLeft <= 0) return;

    const valDifference = firstImgWidth - Math.abs(positionDiff);
    if (carouselRef.current.scrollLeft > prevScrollLeft) {
      return carouselRef.current.scrollLeft += positionDiff > firstImgWidth / 3 ? valDifference : -positionDiff;
    }
    carouselRef.current.scrollLeft -= positionDiff > firstImgWidth / 3 ? valDifference : -positionDiff;
  };

  const dragStart = (e) => {
    setIsDragStart(true);
    setPrevPageX(e.pageX || e.touches[0].pageX);
    setPrevScrollLeft(carouselRef.current.scrollLeft);
  };

  const dragging = (e) => {
    if (!isDragStart) return;
    e.preventDefault();
    setIsDragging(true);
    carouselRef.current.classList.add('dragging');
    setPositionDiff((e.pageX || e.touches[0].pageX) - prevPageX);
    carouselRef.current.scrollLeft = prevScrollLeft - positionDiff;
    showHideIcons();
  };

  const dragStop = () => {
    setIsDragStart(false);
    carouselRef.current.classList.remove('dragging');

    if (!isDragging) return;
    setIsDragging(false);
    autoSlide();
  };

  const handleArrowClick = (direction) => {
    if (!carouselRef.current || !firstImgWidth) return;
    carouselRef.current.scrollLeft += direction === 'left' ? -firstImgWidth : firstImgWidth;
    setTimeout(() => showHideIcons(), 60);
  };

  return (
    <div className="wrapper">
      <FaAngleLeft id="left" className="arrow" onClick={() => handleArrowClick('left')} />
      <div className="carousel" ref={carouselRef} onMouseDown={dragStart} onMouseMove={dragging} onMouseUp={dragStop} onTouchStart={dragStart} onTouchMove={dragging} onTouchEnd={dragStop}>
        <img src={img1} alt="img" draggable="false" />
        <img src={img2} alt="img" draggable="false" />
        <img src={img3} alt="img" draggable="false" />

      </div>
      <FaAngleRight id="right" className="arrow" onClick={() => handleArrowClick('right')} />
      </div>
      );
}

export default Carousel;

