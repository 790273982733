import React from 'react';
import './styles.css';
import Cards from '../components/ServiceCards';
import HeroSection from '../components/HeroSection';
import ImageGallery from '../components/ImageGallery';

function Home() {
  return (
    <>
      <HeroSection />
      <ImageGallery/>
    </>
  );
}

export default Home;
