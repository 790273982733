import React from 'react';
import './ServiceCards.css';


function Cards() {
  return (
    <div className='cards'>
      <div class="title">
           
        </div>
        <div class="services-row">
            <div class="services-col">
                <i class="fas fa-book-open"></i>
                <h2>Wedding decoration</h2>
            </div>
            <div class="services-col">
                <i class="fas fa-camera"></i>
                <h2>Photo and videography</h2>
            </div>
            <div class="services-col">
                <i class="fas fa-brush"></i>
                <h2>Beauty & Makeup</h2>
            </div>
            <div class="services-col">
                <i class="fab fa-pagelines"></i>
                <h2>Flower Decoration</h2>
            </div>
            <div class="services-col">
                <i class="fas fa-birthday-cake"></i>
                <h2>Wedding Cakes</h2>
            </div>
            <div class="services-col">
                <i class="fas fa-music"></i>
                <h2>DJ</h2>
            </div>
            <div class="services-col">
                <i class="fas fa-utensils"></i>
                <h2>Food and Catering</h2>
            </div>
        </div>
    </div>
  );
}

export default Cards;
