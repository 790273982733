import "./Services.css";
import React from "react";
import Cards from "../components/ServiceCards"

const Services = () => {
  return (
    <div className="cards">
      <div class="title">
        <h1>
        <h1><span>O</span>ur&nbsp;<span>S</span>ervice</h1>
        </h1>

        <div class="aboutus">
          <div class="aboutus-text">
            <h3>
              Welcome to our services page! At Assam Weddings, we strive to
              provide you with the best quality services that cater to all your
              needs. We have a team of experts who are dedicated to ensuring
              that you receive nothing but the best.
            </h3>

            <h3>
              {" "}
              At Assam Weddings, we believe in transparency, and we keep you
              informed at every step of the way. Our team will work closely with
              you to ensure that your expectations are met, and you are
              satisfied with our services.
            </h3>
          </div>
        </div>
        <Cards />
      </div>
    </div>
  );
};

export default Services;
