import React from 'react';
import '../App.css';
import './HeroSection.css';
import Carousel from './Carousel';
import ImageGallery from './ImageGallery';


function HeroSection() {
  return (
    <div>
      <Carousel/>
    </div>
  );
}

export default HeroSection;
