import React from "react";
import heroImage from "../images/img-8.jpg";
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <>
      <div className="hero-wrapper">
        <img src={heroImage} alt="Hero" />
      </div>
      <div class="aboutus">
        <div class="aboutus-text">
          <h2>WHO ARE WE?</h2>
          <p>
            Welcome to our website! We are a team of passionate and experienced
            wedding planners with over 5 years of expertise in creating
            unforgettable moments. Our love for weddings and commitment to
            excellence have made us a trusted choice among couples looking for a
            seamless and extraordinary wedding planning experience.
          </p>

          <p>
            {" "}
            With our extensive experience in the industry, we have successfully
            planned and executed a wide range of weddings, from intimate
            gatherings to grand celebrations. Our meticulous attention to
            detail, creative flair, and personalized approach make each wedding
            a unique and memorable affair.
          </p>

          <h2>PLAN AND PLAN</h2>

          <p>
            Our team of skilled planners and coordinators are well-versed in the
            latest trends, industry best practices, and local vendor networks,
            ensuring that your wedding is nothing short of perfection. We take
            pride in our ability to seamlessly manage all the logistics,
            timelines, and coordination, leaving you free to truly enjoy your
            special day without any worries.
          </p>

          <p>
            Our commitment to excellence extends beyond just planning your
            wedding day. We also provide invaluable guidance, advice, and
            support throughout the entire planning process, making it a
            stress-free and enjoyable journey for you. We believe that building
            a strong relationship with our couples is key to creating a wedding
            that reflects your unique personalities and love story.
          </p>

          <h2>THE BIG DAY</h2>

          <p>
            We are truly honored to be a part of your wedding planning journey
            and look forward to helping you create cherished memories that will
            last a lifetime. Contact us today to start planning your dream
            wedding with us!
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
